(function () {
    var errorDiv = document.createElement("div");
    var logDiv = document.createElement("div");
    document.body.appendChild(errorDiv);
    document.body.appendChild(logDiv);

    window.tryRun = function (name, func) {
        try {
            func();
        }
        catch (ex) {
            if (window.inDebug) {
                var error = document.createElement("p");
                if (name) {
                    error.innerText = name + " - " + ex.stack;
                } else {
                    error.innerText = ex.stack;
                }
                errorDiv.appendChild(error);
                console.log(ex.stack);
            }
        }
    };
    window.debugLog = function (text) {
        var msg = document.createElement("p");
        error.innerText = text;
        logDiv.appendChild(msg);
    };
})();