window.tryRun("background-blend-mode", function () {
	let init = function () {
		var supportsBackgroundBlendMode = window.getComputedStyle(document.body).backgroundBlendMode;

		if (typeof supportsBackgroundBlendMode === "undefined") {
			createBlendedBackgrounds();
		}
	}

	let createBlendedBackgrounds = function () {
		var els = document.querySelectorAll("[class*='background-blend-mode-']");

		for (var i = 0; i < els.length; i++) {
			var el = els[i];
			var styles = window.getComputedStyle(els[i]);
			var type = styles.getPropertyValue('--background-blend-mode') || 'multiply';
			var image = styles.backgroundImage.slice(4, -1).replace(/"/g, "");
			var color = styles.backgroundColor;

			processElement(el, type, image, color);
		}
	};

	let processElement = function(el, type, image, color) {
		var backgroundImageURL = image;
		var backgroundColor = color;

		createBlendedBackgroundImageFromURLAndColor(backgroundImageURL, backgroundColor, type, function(imgData) {
			el.style.backgroundImage = "url(" + imgData + ")";
		});
	};

	let createBlendedBackgroundImageFromURLAndColor = function(url, color, type, callback) {
		var img = document.createElement("img");

		img.src = url;
		img.onload = function() {
			var canvas = document.createElement("canvas");
			canvas.width = this.naturalWidth;
			canvas.height = this.naturalHeight;

			var context = canvas.getContext("2d");
			context.globalCompositeOperation = type;
			context.drawImage(this, 0, 0);
			context.fillStyle = color;
			context.fillRect(0, 0, canvas.width, canvas.height);

			var data = canvas.toDataURL("image/jpeg");
			callback(data);
		};
	};

	init();
});