window.tryRun("share", function () {
	let clearSelection = function () {
		var sel;
		if ((sel = document.selection) && sel.empty) {
			sel.empty();
		} else {
			if (window.getSelection) {
				window.getSelection().removeAllRanges();
			}
			var activeEl = document.activeElement;
			if (activeEl) {
				var tagName = activeEl.nodeName.toLowerCase();
				if (tagName == "textarea" ||
					(tagName == "input" && activeEl.type == "text")) {
					activeEl.selectionStart = activeEl.selectionEnd;
				}
			}
		}
	}

	let copyEventHandler = function (e) {
		e.preventDefault();

		var element = e.target;
		if (element.hasAttribute('data-element-to-copy')) {
			var elementToCopy = document.querySelector(element.getAttribute('data-element-to-copy'))
			elementToCopy.select();
			document.execCommand("Copy");
			clearSelection();

			var copied = document.querySelector('.copied');
			copied.classList.remove('hidden');
			element.classList.add('hidden');
		}
	}

	let init = function () {
		var copyElement = document.querySelector('.copy');
		if (copyElement) {
			copyElement.addEventListener('click', copyEventHandler, false);
		}

		var shareModal = document.getElementById('shareModal');
		if (shareModal) {
			shareModal.addEventListener('modal.show', function (e) {
				var copied = document.querySelector('.copied');
				copied.classList.add('hidden');
				copyElement.classList.remove('hidden');
			}, false);
		}
	}
	init();
});