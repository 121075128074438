import './tryrun';

import * as conditioner from 'conditioner-core/conditioner-core';
try {
    conditioner.addPlugin({
        moduleSetName: name => `${name}.js`,
        moduleGetConstructor: module => module.default,
        moduleImport: name => import(`${name}`),
        monitor: {
            name: 'visible',
            create: (context, element) => ({
                matches: false,
                addListener(change) {
                    new IntersectionObserver(entries => {
                        this.matches = entries.pop().isIntersecting == context;
                        change();
                    }).observe(element);
                }
            })
        }
    });
    conditioner.hydrate(document.documentElement);
} catch {

}

import './background-blend-mode';
import './matchheight';
import './sticky';
import './scrollspy';
import './modal';
import './share';
import './collapse';
import './dropdown';
import './navigation';
import './quicksearch';
import './scrolltotop';
import './form';
import './cookie-notice';
import './tabcontrols';
import './seoimage';
import './google-analytics';
import './notice-message';
import './displayLoginPanel';
import './contactdismissmobile';

window.__litium = window.__litium || {};
const preloadState = window.__litium.preloadState || {};

// iOS Modal Fix
var isMobile = {
	iOS: function () {
		return navigator.userAgent.match(/iPhone|iPad|iPod/i);
	}
}

if (isMobile.iOS()) {
	document.body.classList.add('apple-ios');
}
