window.tryRun("match-height", function () {
    'use strict';

    function throttle(fn, threshhold) {
        var last = void 0, deferTimer = void 0;

        return function () {
            var now = Date.now();

            if (last && now < last + threshhold) {
                clearTimeout(deferTimer);
                deferTimer = setTimeout(function () {
                    last = now;
                    fn();
                }, threshhold);
            } else {
                last = now;
                fn();
            }
        };
    }

    var errorThreshold = 1; // in px
    var initialized = false;
    var allElements = {};
    var allMinHeight = {};
    //var remains = void 0;

    var MatchHeight = {
        init: function init() {
            initialized = true;
            var initElements = document.querySelectorAll('[data-match-height]');

            for (var i = 0; i < initElements.length; i++) {
                var className = initElements[i].getAttribute("data-match-height");

                if (allElements[className] == null) {
                    allElements[className] = [];
                    allMinHeight[className] = 0;
                }

                allElements[className].push(initElements[i]);
            }
            MatchHeight.update();
        },
        check: function check(el) {
            var initElements = el.querySelectorAll('[data-match-height]');

            for (var i = 0; i < initElements.length; i++) {
                var className = initElements[i].getAttribute("data-match-height");

                if (allElements[className] == null) {
                    allElements[className] = [];
                    allMinHeight[className] = 0;
                }

                allElements[className].push(initElements[i]);
            }
        },
        update: function update(force = false) {
            if (!initialized) {
                MatchHeight.init();

                return;
            }

            process(force);
        },
        remove: function update() {
            if (!initialized)
                return;

            for (var key of Object.keys(allElements)) {
                allElements[key].forEach(function (el) {
                    el.style.minHeight = "";
                    el.visible = undefined;
                });
                allMinHeight[key] = 0;
            }

            initialized = false;
        }
    };

    function process(force = false) {

        if (force) {
            for (var key of Object.keys(allElements)) {
                allElements[key].forEach(function (el) {
                    el.style.minHeight = "";
                    el.visible = undefined;
                });
                allMinHeight[key] = 0;
            }
        }

        for (var key of Object.keys(allElements)) {
            var minHeight = allMinHeight[key];
            var padding = 0;
            var style = null;

            allElements[key].forEach(function (el) {
                if (style == null) {
                    style = window.getComputedStyle(el);
                    padding = parseFloat(style.getPropertyValue('padding-top'), 10) + parseFloat(style.getPropertyValue('padding-bottom'), 10) + parseFloat(style.getPropertyValue('border-top-width'), 10) + parseFloat(style.getPropertyValue('border-bottom-width'), 10);
                }

                if (el.visible !== true) {
                    var height = el.clientHeight;//rect.bottom - rect.top;
                    if (height > 0) {
                        if (height + padding > minHeight)
                            minHeight = height + padding;
                        el.visible = true;
                    } else {
                        el.visible = false;
                    }
                }
            });

            allMinHeight[key] = minHeight;
        }

        for (var key of Object.keys(allElements)) {
            var minHeight = allMinHeight[key];

            allElements[key].forEach(function (el) {
                if (!el.visible)
                    return;
                //console.log("aa");
                //el.style.minHeight = minHeight + "px";
                el.style.minHeight = minHeight + "px";
				/*if (el.parentNode != null && el.parentNode.parentNode != null && el.parentNode.parentNode.parentNode != null && !el.parentNode.parentNode.parentNode.classList.contains('list'))
					el.style.minHeight = minHeight + "px";
				else
					el.style.minHeight = '';*/
            });
        }
    };

    var throttledUpdate = throttle(function () { MatchHeight.update(true); }, 200);

    MatchHeight.init();
    window.addEventListener('resize', throttledUpdate);

    document.addEventListener("DOMContentLoaded", function () {
        MatchHeight.update();
    });

	document.matchheight = {
		init: function () {
			MatchHeight.init();
        },
        check: function (el) {
            MatchHeight.check(el);
        },
		update: function (force = false) {
			MatchHeight.update(force);
		},
		remove: function () {
			MatchHeight.remove();
		}
	}
});