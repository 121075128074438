window.tryRun("notice-message", function () {
	var alert = document.querySelector(".notice-message");

	if (!alert)
		return;

	if (!getCookie("noticeMessage-" + alert.getAttribute('data-message-id')))
		alert.classList.add('show');

	var close = alert.querySelector(".close");
	close.addEventListener("click", function () {
		setCookie("noticeMessage-" + alert.getAttribute('data-message-id'), true, 14);
		alert.classList.remove('show');
	});

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}

		return "";
	}
});