window.tryRun("tabcontrols", function () {
	var floorplans = document.querySelectorAll('.floor-plans');
	for (var x = 0; x < floorplans.length; x++) {
		var i;
		var forId = floorplans[x].getAttribute('id');
		var elements = document.getElementById(forId).querySelectorAll('[data-toggle="tab"]');

		var nextBtn = floorplans[x].getElementsByClassName('carousel-control-next')[0];
		var prevBtn = floorplans[x].getElementsByClassName('carousel-control-prev')[0];

		if (nextBtn && prevBtn) {
			if (elements.length <= 1) {
				nextBtn.style.display = 'none';
				prevBtn.style.display = 'none';
			}

			nextBtn.addEventListener('click', function (e) {
				e.preventDefault();

				for (i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains('active')) {
						break;
					}
				}

				if (i < elements.length - 1) {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[i + 1].classList.add('active');
					document.querySelector(elements[i + 1].getAttribute('href')).classList.add('active');
				} else {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[0].classList.add('active');
					document.querySelector(elements[0].getAttribute('href')).classList.add('active');
				}
			});

			prevBtn.addEventListener('click', function (e) {
				e.preventDefault();

				for (i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains('active')) {
						break;
					}
				}

				if (i != 0) {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[i - 1].classList.add('active');
					document.querySelector(elements[i - 1].getAttribute('href')).classList.add('active');
				} else {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[elements.length - 1].classList.add('active');
					document.querySelector(elements[elements.length - 1].getAttribute('href')).classList.add('active');
				}
			});
		}
	}

	var contacts = document.querySelectorAll('.contacts');
	for (var x = 0; x < contacts.length; x++) {
		var i;
		var forId = contacts[x].getAttribute('id');
		var elements = document.getElementById(forId).querySelectorAll('[data-toggle="tab"]');

		var nextBtn = contacts[x].getElementsByClassName('carousel-control-next')[0];
		var prevBtn = contacts[x].getElementsByClassName('carousel-control-prev')[0];

		if (elements.length <= 1) {
			nextBtn.style.display = 'none';
			prevBtn.style.display = 'none';
		}

		if (nextBtn && prevBtn) {
			nextBtn.addEventListener('click', function (e) {
				e.preventDefault();

				for (i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains('active')) {
						break;
					}
				}

				if (i < elements.length - 1) {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[i + 1].classList.add('active');
					document.querySelector(elements[i + 1].getAttribute('href')).classList.add('active');
				} else {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[0].classList.add('active');
					document.querySelector(elements[0].getAttribute('href')).classList.add('active');
				}
			});

			prevBtn.addEventListener('click', function (e) {
				e.preventDefault();

				for (i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains('active')) {
						break;
					}
				}

				if (i != 0) {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[i - 1].classList.add('active');
					document.querySelector(elements[i - 1].getAttribute('href')).classList.add('active');
				} else {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[elements.length - 1].classList.add('active');
					document.querySelector(elements[elements.length - 1].getAttribute('href')).classList.add('active');
				}
			});
		}
	}

	var spec = document.querySelectorAll('.technical-specifications');
	for (var x = 0; x < spec.length; x++) {
		var i;
		var forId = spec[x].getAttribute('id');
		var elements = document.getElementById(forId).querySelectorAll('[data-toggle="tab"]');

		var nextBtn = spec[x].getElementsByClassName('carousel-control-next')[0];
		var prevBtn = spec[x].getElementsByClassName('carousel-control-prev')[0];

		if (nextBtn && prevBtn) {
			if (elements.length <= 1) {
				nextBtn.style.display = 'none';
				prevBtn.style.display = 'none';
			}

			nextBtn.addEventListener('click', function (e) {
				e.preventDefault();

				for (i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains('active')) {
						break;
					}
				}

				if (i < elements.length - 1) {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[i + 1].classList.add('active');
					document.querySelector(elements[i + 1].getAttribute('href')).classList.add('active');
				} else {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[0].classList.add('active');
					document.querySelector(elements[0].getAttribute('href')).classList.add('active');
				}
			});

			prevBtn.addEventListener('click', function (e) {
				e.preventDefault();

				for (i = 0; i < elements.length; i++) {
					if (elements[i].classList.contains('active')) {
						break;
					}
				}

				if (i != 0) {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[i - 1].classList.add('active');
					document.querySelector(elements[i - 1].getAttribute('href')).classList.add('active');
				} else {
					elements[i].classList.remove('active');
					document.querySelector(elements[i].getAttribute('href')).classList.remove('active');

					elements[elements.length - 1].classList.add('active');
					document.querySelector(elements[elements.length - 1].getAttribute('href')).classList.add('active');
				}
			});
		}
	}
});