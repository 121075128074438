window.tryRun("cookie-notice", function () {
	var cookieAlert = document.querySelector(".cookie-notice");
	var acceptCookies = document.querySelector(".cookie-notice__accept");

	if (!cookieAlert) {
		return;
	}

	cookieAlert.offsetHeight;
	if (!getCookie("acceptCookies")) {
		cookieAlert.classList.add("show");
	}

	acceptCookies.addEventListener("click", function () {
		setCookie("acceptCookies", true, 365);
		cookieAlert.classList.remove("show");
	});

	function setCookie(cname, cvalue, exdays) {
		var d = new Date();
		d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));

		var expires = "expires=" + d.toUTCString();
		document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
	}

	function getCookie(cname) {
		var name = cname + "=";
		var decodedCookie = decodeURIComponent(document.cookie);
		var ca = decodedCookie.split(';');

		for (var i = 0; i < ca.length; i++) {
			var c = ca[i];
			while (c.charAt(0) === ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) === 0) {
				return c.substring(name.length, c.length);
			}
		}

		return "";
	}
});
