import { nextSibling } from './utils';
import { disablePageScroll, enablePageScroll } from 'scroll-lock';

window.tryRun("navigation", function () {
	function elementDepth(el)
	{
		var depth = 0
		while (null !== el.parentElement) {
			el = el.parentElement
			depth++
		}

		return depth
	}

	function updateBackgroundColor()
	{
		var deep = 0;
		var elements = document.querySelectorAll('.navbar__item > .navbar__menu--show');
		if (elements.length > 0)
			deep = elementDepth(elements[elements.length - 1]);

		for (var i = 0; i < elements.length; i++) {
			elements[i].classList.remove('background-menu-color');

			if (elementDepth(elements[i]) == deep)
				elements[i].classList.add('background-menu-color');
		}
	}

	var isMobile = {
		iOS: function () {
			return navigator.userAgent.match(/iPhone|iPad|iPod/i);
		}
	}

	document.addEventListener('click', function (event) {
		if (!event.target.matches('.mobile-menu-toggler')) return;
		event.preventDefault();

		var element = event.target;
		if (element.classList.contains('navbar__icon--menu')) {
			element.classList.toggle('open');

			var menu = nextSibling(element, 'ul.navbar__menu');
			menu.classList.toggle('navbar__menu--show');
			document.body.classList.toggle('mobile__menu--open');

			if (element.classList.contains('open')) {
				if (isMobile.iOS()) {
					disablePageScroll(menu);
				}
			} else {
				if (isMobile.iOS()) {
					enablePageScroll(menu);
				}
			}
		} else {
			element.classList.toggle('navbar__icon--close');
			element.classList.toggle('navbar__icon--open');

			var menu = nextSibling(element.parentNode, 'ul.navbar__menu');
			menu.classList.toggle('navbar__menu--show');
		}

		updateBackgroundColor();
	}, false);

	window.addEventListener('resize', function (event) {
		var element = document.querySelector('.navbar__icon--menu');
		if (element.classList.contains('open')) {
			element.classList.remove('open');

			var menu = nextSibling(element, 'ul.navbar__menu');
			menu.classList.remove('navbar__menu--show');
			document.body.classList.remove('mobile__menu--open');

			if (isMobile.iOS()) {
				enablePageScroll(menu);
			}
		}
	}, false);

	updateBackgroundColor();
});