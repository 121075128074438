import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import { post } from './Services/http';
import autocomplete from './autocompleter';
import { enablePageScroll } from 'scroll-lock';
import { nextSibling } from './utils';

window.tryRun("quicksearch", function () {
	var element = document.querySelector('#quickSearch');
	var link = element.querySelector('div > a');
	var input = element.querySelector('.quick-search__input');
	var submitButton = element.querySelector('.quick-search__submit-button');
	var abortController;

	link.addEventListener('click', function (e) {
		e.preventDefault();

		var el = element.querySelector('.quick-search__form');

		// Show/Hide quick search form
		el.classList.toggle('quick-search__form--force-show');

		// Set focus to input element
		setTimeout(() => {
			input && input.focus();
		}, 0);

		// Close mobile menu (if it's open)
		var nav = document.querySelector('.navbar__icon--menu');
		if (nav.classList.contains('open')) {
			nav.classList.remove('open');

			var menu = nextSibling(nav, 'ul.navbar__menu');
			menu.classList.remove('navbar__menu--show');
			document.body.classList.remove('mobile__menu--open');

			if (isMobile.iOS()) {
				enablePageScroll(menu);
			}
		}
	}, false);

	input.addEventListener('blur', function (e) {
		var el = element.querySelector('.quick-search__form');

		setTimeout(function () {
			if (e.type == 'blur') {
				if (e.relatedTarget !== submitButton) {
					el.classList.remove('quick-search__form--force-show');
				}
			}
		}, 250);
	}, false);

	autocomplete({
		input: input,
		fetch: function (q, callback) {
			// abort any existing, pending request.
			// It's ok to call .abort() after the fetch has already completed, fetch simply ignores it.
			abortController && abortController.abort();
			abortController = new AbortController();

			post('/api/quickSearch', q, abortController).then(response => response.json()).then(result => {
				callback(result);
			});
		},
		render: function (item, currentValue) {
			var itemRow = '';
			if (item.imageSource !== '') {
				itemRow += '<div class="autocomplete-image" style="background-image: url(' + item.imageSource +'); background-size: cover; background-position: center;"></div>';
			}
			itemRow += '<div class="autocomplete-title">' + item.name + '</div>';

			var div = document.createElement("div");
			div.innerHTML = itemRow;

			return div;
		},
		onSelect: function (item) {
			window.location.href = item.url;
		}
	});


	var maxItems = 20;
	var items = document.querySelectorAll('.search-result__item');
	if (items.length > 0) {
		var moreBtn = document.getElementById('searchMoreBtn');
		var hiddenClass = 'hide';

		[].forEach.call(items, function (item, idx) {
			item.classList.remove(hiddenClass);
			if (idx > maxItems - 1) {
				item.classList.add(hiddenClass);
			}
		});

		var hiddenElements = items[0].parentNode.querySelectorAll('.' + hiddenClass);
		moreBtn.querySelector('.count').innerHTML = "(+" + hiddenElements.length + ")";
		moreBtn.addEventListener('click', function () {
			[].forEach.call(hiddenElements, function (item, idx) {
				if (idx < maxItems) {
					item.classList.remove(hiddenClass);
				}
			});

			hiddenElements = items[0].parentNode.querySelectorAll('.' + hiddenClass);
			moreBtn.querySelector('.count').innerHTML = "(+" + hiddenElements.length + ")";
			if (hiddenElements.length === 0) {
				moreBtn.style.display = 'none';
			}
		});

		if (hiddenElements.length === 0) {
			moreBtn.style.display = 'none';
		} else {
			moreBtn.style.display = '';
		}
	}
});