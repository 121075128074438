window.tryRun("googleAnalytics", function () {
	var retryCounter = 0;
	var init = function () {
		setTimeout(() => {
			var trackingElements = document.querySelectorAll('[data-ga="track"]');
			var datalayerElements = document.querySelectorAll('[data-ga="datalayer"]');
			for (var i = 0; i < trackingElements.length; i++) {
				switch (trackingElements[i].tagName) {
					case 'VIDEO':
						trackingElements[i].onplay = function () {
							sendGoogleAnalytics(this);
						};
						break;
					case 'FORM':
						trackingElements[i].addEventListener('form.completed', function () {
							sendGoogleAnalytics(this, true);
						}, false);
						break;
					default:
						trackingElements[i].onclick = function () {
							sendGoogleAnalytics(this);

							if (trackingElements[i].hasAttribute('data-ga-redirect')) {
								var url = trackingElements[i].getAttribute('data-ga-redirect');

								if (url && url.length) {
									document.location.href = url;
								}
							}
						};
						break;
				}
			}

			for (var i = 0; i < datalayerElements.length; i++) {
				var element = datalayerElements[i];

				if (element) {
					element.onclick = function () {
						var category = this.getAttribute('data-category');
						var action = this.getAttribute('data-action');
						var label = this.getAttribute('data-label');
						var dataLayerObject = { 'event': category };

						if (label && action) {
							dataLayerObject[label] = action
						}
						dataLayer.push(dataLayerObject);

						if (this.hasAttribute('data-ga-redirect')) {
							var url = this.getAttribute('data-ga-redirect');

							if (url && url.length) {
								document.location.href = url;
							}
						}
					}
				}
			}
		}, 1000);
	}

	var sendGoogleAnalytics = function (element, form = false) {
		var category = element.getAttribute('data-category');
		var action = element.getAttribute('data-action');
		var label = element.getAttribute('data-label');

		var el = element.querySelector('#ContactForm_Office');
		if (form && el) {
			var value = el.options[el.selectedIndex].text;

			label = label + ': ' + value;
		}

		var el = element.querySelector('#InterestForm_Product');
		if (form && el) {
			var value = el.value;

			label = label + ': ' + value;
		}

		if (window.ga && ga.create)
			ga('send', 'event', category, action, label);
	}

	init();
});