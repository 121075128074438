const Sticky = (function () {
	let el;

	return {
		elements: function () {
			return {
				stickies: [...document.querySelectorAll('[data-scroll="sticky"]')]
			};
		},

		init: function () {
			el = this.elements();
			this.load();
		},

		load: function () {
			this.setupStickyWrap();
			window.addEventListener('scroll', () => this.whenScrolling());
			window.addEventListener('resize', () => this.whenResizing());
		},

		setupStickyWrap: function () {
			el.stickies.forEach((sticky, i) => {
				const stickyWrap = this.addWrap(sticky, 'sticky-wrap');
				const heightToTop = sticky.getBoundingClientRect().top + window.pageYOffset;
				const outerHeight = sticky.getBoundingClientRect().height;

				stickyWrap.parentElement.id = `sticky-content-${i}`;
				sticky.setAttribute('data-originalPosition', heightToTop);
				sticky.setAttribute('data-originalHeight', outerHeight);

				stickyWrap.style.height = outerHeight + 'px';
			});
		},

		addWrap: function (el, className, wrap = 'div') {
			const wrapper = document.createElement(wrap);
			wrapper.classList.add(className);
			el.parentNode.insertBefore(wrapper, el);
			wrapper.appendChild(el);

			return wrapper;
		},

		elementExists: function (el) {
			return typeof (el) != 'undefined' && el != null;
		},

		stickyPosition: function (el) {
			return el.getAttribute('data-originalPosition');
		},

		nextStickyPosition: function (current, next) {
			return next.getAttribute('data-originalPosition') - current.getAttribute('data-originalHeight');
		},

		scrollingPositionTop: function (el) {
			return el.getBoundingClientRect().top + window.pageYOffset;
		},

		offsetTop: function (el) {
			return el.getBoundingClientRect().top;
		},

		scrollingPositionBottom: function (el) {
			return el.getBoundingClientRect().bottom + window.pageYOffset;
		},

		headerPosition: function () {
			return window.pageYOffset;
		},

		bottomSectionHit: function (contentElement, sticky) {
			const contentSection = document.getElementById(contentElement);
			const sectionBottom = contentSection.getBoundingClientRect().bottom + window.pageYOffset;
			const stickyPositionScrolling = sticky.getBoundingClientRect().bottom + window.pageYOffset;

			return stickyPositionScrolling >= sectionBottom;
		},

		whenScrolling: function () {
			el.stickies.forEach((sticky, i) => {
				const nextSticky = el.stickies[i + 1];
				const prevSticky = el.stickies[i - 1];

				if (this.stickyPosition(sticky) < this.headerPosition()) {
					sticky.classList.add('fixed');

					if (this.elementExists(nextSticky)) {
						while (this.scrollingPositionTop(sticky) >= this.nextStickyPosition(sticky, nextSticky) + 50) {
							sticky.classList.add('absolute');
							sticky.style.top = this.nextStickyPosition(sticky, nextSticky);
						}
					}
				} else {
					sticky.classList.remove('fixed');

					if (this.elementExists(prevSticky) && window.pageYOffset <= this.stickyPosition(sticky)) {
						prevSticky.classList.remove('absolute');
						prevSticky.removeAttribute('style');
					}
				}
			});
		},

		whenResizing: function () {
			el.stickies.forEach((sticky, i) => {
				const stickyWrap = sticky.parentNode;
				const outerHeight = sticky.offsetHeight;

				sticky.setAttribute('data-originalHeight', outerHeight);

				stickyWrap.style.height = outerHeight + 'px';
			});
		}
	}
}());

window.tryRun("sticky", function () {
	Sticky.init();
});