export const supportTransitions = 'WebkitTransition' in document.documentElement.style || 'transition' in document.documentElement.style;
export const transitionEndEvent = 'WebkitTransition' in document.documentElement.style ? 'webkitTransitionEnd' : 'transitionend';

export function getClosest(element, selector) {
	var selectorSubstring = selector.substr(1);
	for (; element && element !== document; element = element.parentNode) {
		if (element.parentNode.querySelector(selector) !== null && element.classList.contains(selectorSubstring)) {
			return element;
		}
	}

	return false;
};

export function one(element, event, handler) {
	element.addEventListener(event, function handlerWrapper(e) {
		handler(e);
		element.removeEventListener(event, handlerWrapper, false);
	});
};

export function getTransitionDurationFromElement(element) {
	var duration = supportTransitions ? window.getComputedStyle(element).transitionDuration : 0;
	duration = parseFloat(duration);
	duration = typeof duration === 'number' && !isNaN(duration) ? duration * 1000 : 0;

	return duration;
};

export function emulateTransitionEnd(element, handler) {
	var called = 0, duration = getTransitionDurationFromElement(element);

	duration ? one(element, transitionEndEvent, function (e) {
		!called && handler(e), called = 1;
	}) : setTimeout(function () {
		!called && handler(), called = 1;
	}, 17);
};

export function nextSibling (element, selector) {
	if (selector) {
		var family = element.parentNode.children;
		var index = [].indexOf.call(family, element);
		var siblings = [].filter.call(family, (e, i) => e.matches(selector) && i > index);

		return siblings[0];
	} else {
		return element.nextElementSibling;
	}
}

export function customEvent(eventType, eventName, related) {
	var OriginalCustomEvent = new CustomEvent(eventType + '.' + eventName);
	OriginalCustomEvent.relatedTarget = related;

	this.dispatchEvent(OriginalCustomEvent);
};

// left: 37, up: 38, right: 39, down: 40, spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
let keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

let preventDefault = function (e) {
	e = e || window.event;
	if (e.preventDefault)
		e.preventDefault();

	e.returnValue = false;
}

let preventDefaultForScrollKeys = function (e) {
	if (keys[e.keyCode]) {
		preventDefault(e);

		return false;
	}
}

export function disableScroll () {
	if (window.addEventListener)
		window.addEventListener('DOMMouseScroll', preventDefault, false);

	document.addEventListener('wheel', preventDefault, { passive: false });
	window.onwheel = preventDefault;
	window.onmousewheel = document.onmousewheel = preventDefault;
	window.ontouchmove = preventDefault;
	document.onkeydown = preventDefaultForScrollKeys;
}

export function enableScroll () {
	if (window.removeEventListener)
		window.removeEventListener('DOMMouseScroll', preventDefault, false);

	document.removeEventListener('wheel', preventDefault, { passive: false });
	window.onmousewheel = document.onmousewheel = null;
	window.onwheel = null;
	window.ontouchmove = null;
	document.onkeydown = null;
}
