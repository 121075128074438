document.addEventListener("DOMContentLoaded", function () {
    var modal = document.getElementById('login-panel-modal');
    var container = document.getElementById('login-panel-container');
    var close = document.getElementById('login-panel-close');
    var button = document.getElementById('login-panel-button');

    var togglePanel = function() {
        document.body.classList.toggle('lock-page');

        if (modal)
            modal.classList.toggle('hidden');
    }

    if (button)
        button.addEventListener('click', togglePanel, false);

    if (modal)
        modal.addEventListener('click', togglePanel, false);

    if (container)
        container.addEventListener('click', togglePanel, false);

    if (close)
        close.addEventListener('click', togglePanel, false);
});