
window.tryRun("contactdismissmobile", function () {
    var init = function () {
        if (!dismisser)
            return;

        dismisser.addEventListener("click", function (e) {
            setTimeout(move(0), interval);
        });
    };

    var mobilelock = function () {
        if (!screen)
            return;

        if (screen.style.display === "none" || (window.innerWidth >= 1024)) {
            body.style.overflow = "auto";
        }
        else {
            body.style.overflow = "hidden";
        }

        window.addEventListener("resize", function (e) {
            if (screen.style.display === "none" || (window.innerWidth >= 1024)) {
                body.style.overflow = "auto";
            }
            else {
                body.style.overflow = "hidden";
            }
        });
    };

    var move = function (i) {
        if (!screen)
            return;

        if (i == limit) {
            body.style.overflow = "auto";
            screen.style.display = "none";

            clearInterval();
        }
        else {
            screen.style.top = -i * 10;
            setTimeout(move(++i), interval);
        }
    };

    var interval = 10;
    var limit = 100;
    var body = document.querySelector('.DMB');
    var screen = document.getElementById('contact-mobile');
    var dismisser = document.getElementById('contact-mobile-dismiss');

    mobilelock();
    init();
});